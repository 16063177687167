* {
  font-family: 'Mochiy Pop One', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/liquidism-part-2');

.page-loader {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  height: 100vh;
  background: rgb(255,198,38);
  background: radial-gradient(circle, rgba(255,198,38,1) 0%, rgba(116,228,167,1) 46%, rgba(146,230,255,1) 100%);  z-index: 100000;
  display: block;
  top:0;
  bottom:0;

}

.loadingText2:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

.page-loader .loadLogo {
  font-family: 'Luckiest Guy', cursive;
  background: -webkit-linear-gradient(#8c31c4, #a759d7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  text-align: center;
  top: 30%;
  position: relative;
   letter-spacing: 2px;
  font-size: 40px;
  margin-top: 2%;
}

.loadLogo img {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  animation: zoominoutsinglefeatured 3s infinite;
  border-radius: 100%;
  border: 5px rgb(255, 255, 255) solid;

}

@media screen and (max-width: 499px) {
   

.wrapper {
  margin: 3.125em auto;
  padding: 1em 5em 1em;
  max-width: 100%;
}

h1 {
  color: white;
  font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
  text-align: center;
}

/* - &:nth-child(1) {
			 grid-area: entry1;
		 }
   - useful timesaver if there is a lengthy list
*/
.timeline {
  line-height: 1.5em;
  font-size: 14px;
  transition: all 0.4s ease;
  position: relative;
  counter-reset: section;
  width: 100%;
  
}

.timeline:before {
  content: "";
  width: 10px;
  height: 100%;
  background: #ffffff;
  position: absolute;
  top: 0;
  margin-left: -60px;
  border-radius: 40px 40px;
}

.timeline--entry {
  position: relative;
  background-color: #eeeeee00;
  width: 300px;
  left: -40px;
}


.timeline--entry__detail {
  background: rgb(167,46,183);
  background: linear-gradient(0deg, rgba(167,46,183,1) 45%, rgba(232,121,227,1) 100%);  padding: 1em;
  margin: 0;
  font-size: 18px;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
}

.timeline--entry:before { 
  content: "";
  font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  background-color: #000000;
  border: 0.2em solid rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
  counter-increment: section;
  content: counter(section);
  text-shadow: 0 1px 0 #424242;
  left: -35px;
}

  
  .timeline--entry__title {
    color: white;
    background-color: #43a0d948;
    font-weight: 300;
    padding: 1em;
    font-size: 20px;
    letter-spacing: 2px;
    text-shadow: 0 0 10px #FFFFFF;
    line-height: 30px;
  }
  .timeline--entry__title:before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -40px;
    display: none;
    border-right: 10px solid #43a0d948;
  }
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 18px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 10px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
 
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 7%;
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
    width: 330px;
    height: 231px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
     z-index: 100;
     margin-top: 20%;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 300px;
    height: 126px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 300px;
    height: 134px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 350px;
    height: 136px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 250px;
    height: 250px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 150px;
    height: 220px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 25%;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
    margin-top: 10%;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 170px;
    height: 172px;
    left: 25%;
    position: absolute;
    width: 230px;
    height: 256px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 35px;
    padding: 5px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 7%;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
   
   

  .wrapper {
    margin: 3.125em auto;
    padding: 1em 5em 1em;
    max-width: 100%;
  }
  
  h1 {
    color: white;
    font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
    text-align: center;
  }
  
  /* - &:nth-child(1) {
         grid-area: entry1;
       }
     - useful timesaver if there is a lengthy list
  */
  .timeline {
    line-height: 1.5em;
    font-size: 14px;
    transition: all 0.4s ease;
    position: relative;
    counter-reset: section;
    width: 100%;
    
  }
  
  .timeline:before {
    content: "";
    width: 10px;
    height: 100%;
    background: #ffffff;
    position: absolute;
    top: 0;
    margin-left: -60px;
    border-radius: 40px 40px;
  }
  
  .timeline--entry {
    position: relative;
    background-color: #eeeeee00;
    width: 400px;
    left: -40px;
  }
  
  
  .timeline--entry__detail {
    background: rgb(167,46,183);
    background: linear-gradient(0deg, rgba(167,46,183,1) 45%, rgba(232,121,227,1) 100%);  padding: 1em;
    margin: 0;
    font-size: 18px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .timeline--entry:before { 
    content: "";
    font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    background-color: #000000;
    border: 0.2em solid rgb(255, 255, 255);
    border-radius: 50%;
    position: absolute;
    counter-increment: section;
    content: counter(section);
    text-shadow: 0 1px 0 #424242;
    left: -35px;
  }
  
    
    .timeline--entry__title {
      color: white;
      background-color: #43a0d948;
      font-weight: 300;
      padding: 1em;
      font-size: 20px;
      letter-spacing: 2px;
      text-shadow: 0 0 10px #FFFFFF;
      line-height: 30px;
    }
    .timeline--entry__title:before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      position: absolute;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      left: -40px;
      display: none;
      border-right: 10px solid #43a0d948;
    }
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 18px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 10px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
 
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 7%;
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
    width: 330px;
    height: 231px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
     z-index: 100;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 300px;
    height: 126px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 300px;
    height: 134px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 350px;
    height: 136px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 18px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 300px;
    height: 300px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 150px;
    height: 220px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 10%;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
    margin-top: 7%;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 170px;
    height: 172px;
    left: 30%;
    position: absolute;
    width: 370px;
    height: 300px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 35px;
    padding: 5px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 7%;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
 
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 21px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 10px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
 
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 3%;
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
    width: 330px;
    height: 231px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
     z-index: 100;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 350px;
    height: 147px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 350px;
    height: 157px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 400px;
    height: 155px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 350px;
    height: 350px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 170px;
    height: 250px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
    margin-top: 3%;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 155px;
    height: 172px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 40px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 7%;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
   
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 550px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 21px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 10px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
  width: 370px;
  height: 259px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 350px;
    height: 147px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 350px;
    height: 157px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 400px;
    height: 155px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 350px;
    height: 350px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 230px;
    height: 338px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
    margin-top: 2%;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 175px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 350px;
    height: 389px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 40px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
 
.connect2Mobile {
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.rmH2 {
  display: none;
}

.discordF2 {
  display: none;
}

.rmboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.rmP2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  contain: content;
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Rm {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: url('https://smassets.pages.dev/assets/rmBg.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Opensea {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 10%;
  background-color: rgba(0, 0, 0, 0);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Mint {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
}

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;

}

.aboutImg {
  width: 30%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 2%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  font-size: 60px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  background: -webkit-linear-gradient(#a7eca7, #048604);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  letter-spacing: 5px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #ee5bf6;
  margin-bottom: 1%;
}

.tokenH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;

  text-align: center;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.mintH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-shadow: #2c2c2c3d 2px 3px 1px;
}

.storyConOs {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-shadow: #2c2c2c3d 2px 3px 1px;
  margin-bottom: 550px;
}

.storyConRm {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #ee5bf6 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #0d158b 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm3 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #f0831f 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm4 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #89fe55 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm5 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #e9b237 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}


.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 23px;

}

.storyCon2Rm {
  font-size: 20px;

}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 70px;
  text-align: center;

}

.intro img {
  width: 60%;
  z-index: 100000;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  border: #51e240 10px solid;
  border-radius: 50px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: none;
  flex-flow: column nowrap;
  margin-top: auto;
  margin-bottom: auto;

  align-items: center;
  justify-content: flex-end;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;

  color: #96ec1f;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;

  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;

  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #7a1883;
}


.btnfos-0-2-2:hover:active {
  background-color: #7a1883;
}

.btnfos-0-3-1 {
  margin-top: 20px;
  background-color: #00bb00;
  color: #ffffff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;

  transition: transform .2s;
}

btnfos-0-3-1:active {
  background-color: #7a1883;

}

.btnfos-0-3-1:focus {
  background-color: #7a1883;
}



@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.btnfos-0-3 {
  margin-top: 20px;
  background-color: #00bb00;
  color: #ffffff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: transform .2s;
  border: none;

  transition: transform .2s;
}

.btnfos-0-3:active {
  background-color: #7a1883;

}

.btnfos-0-3:focus {
  background-color: #7a1883;
}

.btnfos-0-3:hover {
  transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;

  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #48cf5e;

  font-size: 30px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #35292400;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.middleH {
  width: 400px;
  height: 280px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -1%;
  z-index: 100;
}

.middleM {
  width: 400px;
  height: 166px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  z-index: 100;
}

.middleS {
  width: 400px;
  height: 168px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.middleO {
  width: 400px;
  height: 179px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.middleR {
  width: 500px;
  height: 194px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}


.wallet2 {
  z-index: 1000;
  background-color: #2bc611;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 50px;
  width: 200px;
  transition: transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;

  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;

}

.wallet2:hover {
  transform: scale(1.1);
}

.artSub {
  color: white;
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
  font-family: 'Luckiest Guy', cursive;
  transition: 0.5s ease;
  margin-top: 3%;

}

.artSub:hover {
  transform: scale(1.1);
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;

  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  background: -webkit-linear-gradient(#d8a2f8, #8729bd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 20px;
  padding-bottom: 10px;
}

.footer {}

.logoF {
  width: 12%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 2%;
  border-radius: 100%;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 86px;
}

.sop {
  width: 130px;
  height: 59px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.logoDiv {
  margin-top: auto;
  margin-bottom: auto;
}

.logoDiv1 {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: flex-start;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 25px;

  cursor: pointer;
  z-index: 10000;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 17px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #afe0db;
  color: white;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 50%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;

}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  background: rgb(24, 135, 177);
  background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
  margin-top: -5%;
  z-index: 1000;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 400px;
  height: 457px;
  border-radius: 20px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.mintCon {
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
}

.year {
  font-weight: 500;
  font-size: 30px;
  text-decoration: underline;
}


.rmBoxMain2 {
  margin-top: 30%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 200px;
  height: 189px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 24%;
  margin-top: -100%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 410vh;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 20%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  filter: url("#goo");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bgImg {
  background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.web {
  width: 100%;
  height: 100%;
  position: absolute;
}


.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  font-size: 23px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  text-align: center;
  background-color: #8aa7ec;
}

.winDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
}

.winDiv img {
  width: 60px;
  height: 60px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  animation: zoominoutsinglefeatured 5s infinite;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1.3, 1.3);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1.3, 1.3);
  }
}

.winCon {
  color: #fff18d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #ff6b81;
}

.winCon2 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #473e61;
}

.winCon3 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #8f5c4a;
}

.winCon4 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #21b572;
}

.winCon5 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #253a84;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;
}

.teamCon img {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border-radius: 10px;
  border: 3px #febf00 solid;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 20px;
  text-align: center;
  font-family: 'Mochiy Pop One', sans-serif;

}

.memberName1 {
  font-size: 33px;
  text-align: center;
  font-family: 'Mochiy Pop One', sans-serif;

}

.memberCon {
  font-size: 20px;
  text-align: center;
}

.NameAndTwitter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.NameAndTwitter img {
  width: 30px;
  height: 30px;
}

.twitterTeam {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.twitterTeamDiv {
  background-color: #ffffff;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 2%;
  padding: 10px;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.5s ease;

}

.twitterTeamDiv:hover {
  transform: scale(1.2);
  filter: none;
}

.art {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 50%;
  border: 2px solid greenyellow;
  transition: transform 1s;
  filter: grayscale(100%);
  transition: 0.5s ease;
}

.art:hover {
  transform: scale(1.1);
  filter: none;
}

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.lightrope li {
  position: relative;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: #00f7a5;
  box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
  -webkit-animation-name: flash-1;
  animation-name: flash-1;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n+1) {
  background: aqua;
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
  -webkit-animation-name: flash-2;
  animation-name: flash-2;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n+2) {
  background: #f70094;
  box-shadow: 0px 4.6666666667px 24px 3px #f70094;
  -webkit-animation-name: flash-3;
  animation-name: flash-3;
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n+1) {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.3333333333px;
  border-radius: 3px;
  top: -4.6666666667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.6666666667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@-webkit-keyframes flash-1 {

  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-1 {

  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@-webkit-keyframes flash-2 {

  0%,
  100% {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px aqua;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-2 {

  0%,
  100% {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px aqua;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@-webkit-keyframes flash-3 {

  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

@keyframes flash-3 {

  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

.footerIcons {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1%;
}

.footerIcons img {
  width: 45px;
  height: 45px;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.5s ease;
  cursor: pointer;
}

.footerIcons img:hover {
  transform: scale(1.3);
  filter: none;
}

/* Start https://www.cursors-4u.com */

/* End https://www.cursors-4u.com */

/* Basic Reset */

/* Background */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: -10%;
}

/* Footprint */
.footprint {
  position: absolute;
  top: 20%;
  left: 0;
  animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
  text-align: center;
}

/* Image Size */
.foot {
  max-width: 20%;
}

/* Right Footprint */
.right {
  margin: 5rem;
  animation-delay: 250ms;
}

/* Walking Animation */
@keyframes walk {
  to {
    transform: translateX(100vw);
  }
}

/* Opacity During Walking */
@keyframes walkopacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }
}

.stickyGhost {
  width: 250px;
  height: 367px;
  position: sticky;
  position: -webkit-sticky;
  right: 100%;
  margin-top: -40%;
  bottom: 0;
  transform: rotate(65deg);
}

.animation-wrapper {
  background: #ace5ee;
  left: 0;
  position: absolute;
  right: 0;
}

.water {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.water ul.waves {
  list-style: none;
  margin: 0;
  padding: 0;
}

.water ul.waves li {
  background-repeat: repeat-x;
}

.water ul.waves li.wave-one {
  animation: wave 8.7s linear infinite;
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  bottom: 0px;
  height: 50px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 10;
  background-image: url('https://smassets.pages.dev/assets/wave1.png');
  background-size: contain;
}

.water ul.waves li.wave-two {
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation: wave 10s linear infinite;
  bottom: 0px;
  height: 84px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 9;
  background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
  background-size: contain;
}

.water ul.waves li.wave-three {
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation: wave 25s linear infinite;
  bottom: 0;
  height: 190px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 8;
  background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
  background-size: contain;
}

.water ul.waves li.wave-four {
  -webkit-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation: wave 25s linear infinite;
  bottom: 0;
  height: 205px;
  left: 0;
  position: absolute;
  right: 0;
  background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
  background-size: contain;
}

.boat {
  animation: boat 3s linear infinite;
  background-repeat: no-repeat;
  bottom: 175px;
  height: 145px;
  left: 50%;
  position: absolute;
  width: 350px;
  height: 389px;
  background-image: url('https://smassets.pages.dev/assets/boat.png');
  background-size: contain;
  -webkit-transform: scaleX(-1);
}

.cloud {
  animation: cloud 25s linear infinite;
  background-repeat: no-repeat;
  bottom: 280px;
  height: 165px;
  left: 110px;
  position: absolute;
  width: 297px;
  background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
  background-size: contain;
}

.cloud2 {
  animation: cloud 25s linear infinite;
  background-repeat: no-repeat;
  bottom: 450px;
  height: 165px;
  left: 0;
  position: absolute;
  width: 297px;
  background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
  background-size: contain;
}

.dolphin {
  animation: fish 15s linear infinite;
  background-repeat: no-repeat;
  bottom: 45px;
  height: 80px;
  left: 20%;
  position: absolute;
  width: 124px;
  z-index: 9;
}





/*Animation Keyframe Section*/

@keyframes wave {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1920px 0;
  }
}

@keyframes boat {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes cloud {
  0% {
    left: -30%;
  }

  100% {
    left: 100%;
  }
}

.transition {
  width: 100%;
}

.openseaButton {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background-color: #58d4ff;
  color: #ffffff;
  border: none;
  font-size: 50px;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0 5px #409cbb;
  transition: 0.5s ease;
  padding-left: 15px;
  padding-right: 15px;
  background-image: linear-gradient(to right,
      #ff7d84,
      #ff7d84 16.65%,
      #ff733f 16.65%,
      #ff733f 33.3%,
      #39b6ec 33.3%,
      #39b6ec 49.95%,
      #ecd435 49.95%,
      #ecd435 66.6%,
      #bc3ceb 66.6%,
      #bc3ceb 83.25%,
      #fe8abe 83.25%,
      #fe8abe 100%,
      #E7484F 100%);
  animation: slidebg 2s linear infinite;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.openseaButton:hover {

  color: white;
}
}

@media screen and (min-width: 1920px) {
 
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 650px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 30px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 10px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
    width: 750px;
    height: 525px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -3%;
    z-index: 100;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 580px;
    height: 244px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 580px;
    height: 260px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 620px;
    height: 241px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 28px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 650px;
    height: 650px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 55px;
    height: 55px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 300px;
    height: 441px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 160px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 430px;
    height: 478px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 65px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 650px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 35px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 10px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
    width: 890px;
    height: 623px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -3%;
    z-index: 100;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 650px;
    height: 273px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 650px;
    height: 291px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 700px;
    height: 272px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 28px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 750px;
    height: 750px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 55px;
    height: 55px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 450px;
    height: 661px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 160px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 430px;
    height: 478px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 72px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}

@media screen and (min-width: 3840px) {
  
  .connect2Mobile {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: url('https://smassets.pages.dev/assets/rmBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Opensea {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://smassets.pages.dev/assets/stripes.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://smassets.pages.dev/assets/Minting-Cover.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 1%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
  }
  
  .storyConOs {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: #2c2c2c3d 2px 3px 1px;
    margin-bottom: 650px;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 55px;
  
  }
  
  .storyCon2Rm {
    font-size: 20px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    border: #51e240 20px solid;
    border-radius: 50px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: none;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus {
    background-color: #7a1883;
  }
  
  
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active {
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus {
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .middleH {
    width: 1300px;
    height: 910px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -3%;
    z-index: 100;
  }
  
  .middleM {
    width: 400px;
    height: 166px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    z-index: 100;
  }
  
  .middleS {
    width: 1000px;
    height: 421px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleO {
    width: 1000px;
    height: 447px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  .middleR {
    width: 1100px;
    height: 492px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #2bc611;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 3%;
  
  }
  
  .artSub:hover {
    transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    background: -webkit-linear-gradient(#d8a2f8, #8729bd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 43px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 12%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
    border-radius: 100%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 1000px;
    height: 1000px;
   }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    filter: url("#goo");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .bgImg {
    background: url('https://smassets.pages.dev/assets/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1.3, 1.3);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 25px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    transform: scale(1.2);
    filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: transform 1s;
    filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    transform: scale(1.1);
    filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 65px;
    height: 65px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    transform: scale(1.3);
    filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .stickyGhost {
    width: 650px;
    height: 954px;
    position: sticky;
    position: -webkit-sticky;
    right: 100%;
    margin-top: -40%;
    bottom: 0;
    transform: rotate(65deg);
  }
  
  .animation-wrapper {
    background: #ace5ee;
    left: 0;
    position: absolute;
    right: 0;
  }
  
  .water {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  
  .water ul.waves {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .water ul.waves li {
    background-repeat: repeat-x;
  }
  
  .water ul.waves li.wave-one {
    animation: wave 8.7s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    bottom: 0px;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    background-image: url('https://smassets.pages.dev/assets/wave1.png');
    background-size: contain;
  }
  
  .water ul.waves li.wave-two {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 10s linear infinite;
    bottom: 0px;
    height: 84px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 9;
    background-image: url(https://i.postimg.cc/P5hv05rh/wave2.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-three {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 190px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 8;
    background-image: url(https://i.postimg.cc/63Dyc91k/wave3.png);
    background-size: contain;
  }
  
  .water ul.waves li.wave-four {
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation: wave 25s linear infinite;
    bottom: 0;
    height: 205px;
    left: 0;
    position: absolute;
    right: 0;
    background-image: url(https://i.postimg.cc/1tg8DgM0/wave4.png);
    background-size: contain;
  }
  
  .boat {
    animation: boat 3s linear infinite;
    background-repeat: no-repeat;
    bottom: 160px;
    height: 145px;
    left: 50%;
    position: absolute;
    width: 430px;
    height: 478px;
    background-image: url('https://smassets.pages.dev/assets/boat.png');
    background-size: contain;
    -webkit-transform: scaleX(-1);
  }
  
  .cloud {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 280px;
    height: 165px;
    left: 110px;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .cloud2 {
    animation: cloud 25s linear infinite;
    background-repeat: no-repeat;
    bottom: 450px;
    height: 165px;
    left: 0;
    position: absolute;
    width: 297px;
    background-image: url(https://i.postimg.cc/VNkrLZCV/cloud-md.png);
    background-size: contain;
  }
  
  .dolphin {
    animation: fish 15s linear infinite;
    background-repeat: no-repeat;
    bottom: 45px;
    height: 80px;
    left: 20%;
    position: absolute;
    width: 124px;
    z-index: 9;
  }
  
  
  
  
  
  /*Animation Keyframe Section*/
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 1920px 0;
    }
  }
  
  @keyframes boat {
    0% {
      transform: rotate(0);
    }
  
    50% {
      transform: rotate(-3deg);
    }
  
    100% {
      transform: rotate(0);
    }
  }
  
  @keyframes cloud {
    0% {
      left: -30%;
    }
  
    100% {
      left: 100%;
    }
  }
  
  .transition {
    width: 100%;
  }
  
  .openseaButton {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: #58d4ff;
    color: #ffffff;
    border: none;
    font-size: 100px;
    padding: 10px;
    border-radius: 50px;
    box-shadow: 0 5px #409cbb;
    transition: 0.5s ease;
    padding-left: 15px;
    padding-right: 15px;
    background-image: linear-gradient(to right,
        #ff7d84,
        #ff7d84 16.65%,
        #ff733f 16.65%,
        #ff733f 33.3%,
        #39b6ec 33.3%,
        #39b6ec 49.95%,
        #ecd435 49.95%,
        #ecd435 66.6%,
        #bc3ceb 66.6%,
        #bc3ceb 83.25%,
        #fe8abe 83.25%,
        #fe8abe 100%,
        #E7484F 100%);
    animation: slidebg 2s linear infinite;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
  
  .openseaButton:hover {
  
    color: white;
  }
}